<template>
  <div class="contents register wedding funding banner">
    <div class="title flexB">
      <h2>모집 요강 관리</h2>
    </div>
    <div class="box one">
      <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="모집 요강" name="widget">
          <h2 class="bold">모집 요강 미리보기</h2>
        </el-tab-pane>
      </el-tabs>
      <div
        v-if="
          activeName == 'widget' &&
          outlines.filter((item) => item.type == 'widget').length > 0
        "
      >
        <img :src="outlines.filter((item) => item.type == 'widget')[0].image" />
      </div>
    </div>
    <!-- <el-carousel trigger="click" arrow="always" :autoplay="false" v-if="images.length > 0">
      <el-carousel-item v-for="(data, i) in images.filter((item) => item.type == activeName)" :key="i">
        <img :src="data.image" />
      </el-carousel-item>
    </el-carousel> -->
    <div class="box one">
      <h2 class="bold">모집 요강 업로드</h2>
      <div class="flexL">
        <p class="bold">제목</p>
        <textarea v-model="title"></textarea>
      </div>
      <div class="flexL">
        <p class="bold">이미지</p>
        <div class="flexB">
          <div class="filebox">
            <label for="imgUpload">찾아보기</label>
            <input
              type="file"
              ref="images"
              id="imgUpload"
              @change="selectImageAndPreview()"
              accept="image/*"
            />
            <span>280*222 사이즈를 권장합니다.</span>
          </div>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">pdf 파일</p>
        <div class="flexB">
          <div class="filebox">
            <label for="pdfUpload">찾아보기</label>
            <input
              type="file"
              ref="pdf"
              id="pdfUpload"
              @change="selectPdfAndPreview()"
              accept="application/pdf"
            />

            <span>{{ filename }} </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="editMode" style="width: 100%">
      <div
        class="buttonWrap static"
        v-for="(item, i) in outlines.filter((item) => item.type == 'widget')"
        :key="i"
      >
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>

        <button
          class="point delete"
          @click="handleDelete(item._id, item.image)"
        >
          삭제
        </button>
      </div>
    </div>

    <div class="buttonWrap static" v-else>
      <button class="basic" @click="submit">등록</button>
    </div>
  </div>
</template>

<script>
import { uploadFile, deleteFile } from "@/api/file";
import {
  createBanner,
  updateBanner,
  fetchBanner,
  deleteBanner,
} from "@/api/admin";

export default {
  data() {
    return {
      editMode: false,
      activeName: "widget",
      url: "",
      title: "",
      image: "",
      bannerId: "",
      selectedImage: null,
      selectedPdf: null,
      images: [],
      outlines: [],
      pdf: "",
      filename: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "10");
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    selectImageAndPreview() {
      this.selectedImage = this.$refs.images.files[0];
      if (this.selectedImage) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const newImage = {
            type: "widget",
            image: e.target.result,
          };
          const widgetIndex = this.outlines.findIndex(
            (item) => item.type === "widget"
          );
          if (widgetIndex !== -1) {
            this.outlines.splice(widgetIndex, 1, newImage);
          } else {
            this.outlines.push(newImage);
          }
        };
        reader.readAsDataURL(this.selectedImage);
      }
    },

    selectPdfAndPreview() {
      this.selectedPdf = this.$refs.pdf.files[0];
      if (this.selectedPdf) {
        this.filename = this.selectedPdf.name;
      }
    },
    removeSelectedImage() {
      this.selectedImage = null;
    },
    async submit() {
      if (!this.title) {
        this.$alert("제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (!this.selectedImage && !this.image) {
        this.$alert("이미지를 업로드해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (!this.selectedPdf && !this.url) {
        this.$alert("pdf 파일을 업로드해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let imageUrl = this.image;
      let pdfUrl = this.url;
      let currentFilename = this.filename;

      if (this.selectedImage) {
        const formData = new FormData();
        formData.append("file", this.selectedImage);
        const res = await uploadFile(formData);
        if (res.status === 200) {
          imageUrl = res.data.url;
        } else {
          this.$alert("이미지 업로드에 실패했습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      }

      if (this.selectedPdf) {
        const formData = new FormData();
        formData.append("file", this.selectedPdf);
        const res = await uploadFile(formData);
        if (res.status === 200) {
          pdfUrl = res.data.url;
          currentFilename = this.selectedPdf.name;
        } else {
          this.$alert("PDF 업로드에 실패했습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      }

      const data = {
        type: this.activeName,
        title: this.title,
        bannerId: this.bannerId,
        image: imageUrl,
        url: pdfUrl,
        filename: currentFilename,
      };

      if (this.editMode) {
        const updateRes = await updateBanner(data);
        if (updateRes.status === 200) {
          this.$alert("모집 요강이 성공적으로 수정되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
          this.getBanner();
        } else {
          this.$alert("배너 수정에 실패했습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
        }
      } else {
        const createRes = await createBanner(data);
        if (createRes.status === 200) {
          this.$alert("모집 요강이 성공적으로 등록되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
          this.getBanner();
        } else {
          this.$alert("배너 등록에 실패했습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
        }
      }
    },

    handleDelete(id, url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then(async (res) => {
          if (res.status == 200) {
            let data = {
              url: url,
              bannerId: id,
            };
            await deleteBanner(data);
            this.resetForm();
            this.getBanner();
          } else {
            this.$alert("서버오류 입니다. 다시 파일을 업로드 해주세요.", {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    resetForm() {
      this.title = "";
      this.selectedImage = null;
      this.selectedPdf = null;
      this.pdfName = "";
      this.outlines = this.outlines.filter((item) => item.type !== "widget");
    },
    getBanner() {
      fetchBanner().then((res) => {
        if (res.data.status == 200) {
          this.outlines = res.data.data;
          const widget = this.outlines.find((item) => item.type === "widget");
          if (widget) {
            this.title = widget.title;
            this.image = widget.image;
            this.filename = widget.filename;
            this.url = widget.url;
            this.bannerId = widget._id;
            this.editMode = true;
          } else {
            this.editMode = false;
          }
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
