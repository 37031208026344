<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">비교 사진 등록</p>
      </div>
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">
          전문가/업체 명
          <span>*</span>
        </p>
        <input type="text" v-model="providerName" />
      </div>

      <div class="flexL">
        <p class="bold">
          카테고리
          <span>*</span>
        </p>
        <el-select
          v-model="category"
          placeholder="선택"
          class="searchCondition"
        >
          <el-option key="반지" label="반지" value="반지"></el-option>
          <el-option key="목걸이" label="목걸이" value="목걸이"></el-option>
          <el-option key="귀걸이" label="귀걸이" value="귀걸이"></el-option>
          <el-option key="세트" label="세트" value="세트"></el-option>
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">
          다이아 크기
          <span>*</span>
        </p>
        <el-select v-model="size" placeholder="선택" class="searchCondition">
          <el-option
            v-for="(data, i) in dia"
            :key="i"
            :label="data"
            :value="data"
          ></el-option>
          <!-- <el-option key="반지" label="반지" value="반지"></el-option>
          <el-option key="목거리" label="목거리" value="목거리"></el-option>
          <el-option key="팔찌" label="팔찌" value="팔찌"></el-option>
          <el-option key="귀거리" label="귀거리" value="귀거리"></el-option> -->
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">
          스타일
          <span>*</span>
        </p>
        <el-select v-model="style" placeholder="선택" class="searchCondition">
          <el-option
            v-for="(data, i) in styles"
            :key="i"
            :label="data"
            :value="data"
          ></el-option>
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">
          설명
          <span>*</span>
        </p>
        <textarea v-model="comment" />
      </div>
      <div class="flexL">
        <p class="bold">
          Before 사진
          <span>*</span>
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <label for="beforeImg">before</label>
              <input
                type="file"
                ref="beforeImg"
                id="beforeImg"
                @change="sendFile('before')"
                accept="image/*"
              />
            </div>
          </div>
          <div class="flex" v-if="beforeImg">
            <div class="imgWrap">
              <img :src="beforeImg" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(beforeImg, 'before')">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </div>
          </div>
          <em class="notice"
            >대표사진은 16:9 비율로 업로드 됩니다. ex) 500 * 281</em
          >
        </div>
      </div>

      <div class="flexL">
        <p class="bold">
          After 사진
          <span>*</span>
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <label for="afterImg">after</label>
              <input
                type="file"
                ref="afterImg"
                id="afterImg"
                @change="sendFile('after')"
                accept="image/*"
              />
            </div>
          </div>
          <div class="flex" v-if="afterImg">
            <div class="imgWrap">
              <img :src="afterImg" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(afterImg, 'after')">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <!-- <button @click="close" class="close">취소</button> -->
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line delete" @click="handleDelete">
          삭제
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  createBeforeAndAfter,
  fetchBeforeAndAfterDetail,
  revomeBeforeAndAfter,
  upadateBeforeAndAfter,
} from "@/api/admin";
import { uploadFile, deleteFile } from "@/api/file";
import { dia, styles } from "@/config/preference";
export default {
  props: ["providerId", "providerName", "bnfId"],
  data() {
    return {
      editMode: false,
      category: "",
      provider: "",
      afterImg: "",
      beforeImg: "",
      style: "",
      size: "",
      comment: "",
      dia: dia,
      styles: styles,
    };
  },
  watch: {
    fee: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.fee = result;
    },
  },
  mounted() {
    if (this.bnfId) {
      this.editMode = true;
      this.getBeforeAndAfter();
    }
  },
  methods: {
    close() {
      this.$emit("close");
      // if (this.editMode) {
      //   this.getBeforeAndAfter();
      // }
    },

    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },

    getBeforeAndAfter() {
      fetchBeforeAndAfterDetail(this.bnfId).then((res) => {
        if (res.data.status == 200) {
          this.provider = res.data.data.provider;
          this.beforeImg = res.data.data.beforeImg;
          this.afterImg = res.data.data.afterImg;
          this.category = res.data.data.category;
          this.style = res.data.data.style;
          this.size = res.data.data.size;
          this.comment = res.data.data.comment;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    sendFile(type) {
      let file;
      if (type == "before") {
        if (this.beforeImg) {
          this.$alert("이미지 삭제 후 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
        file = this.$refs.beforeImg.files[0];
      } else if (type == "after") {
        if (this.afterImg) {
          this.$alert("이미지 삭제 후 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
        file = this.$refs.afterImg.files[0];
      }
      if (file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "before") {
              this.beforeImg = res.data.url;
            } else if (type == "after") {
              this.afterImg = res.data.url;
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        // if (type == "before") {
        // } else if (type == "after") {
        // }
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            if (type == "before") {
              this.beforeImg = "";
            } else if (type == "after") {
              this.afterImg = "";
            }
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    handleDelete() {
      this.$confirm("데이터를 삭제 하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        let data = {
          afterImg: this.afterImg,
          beforeImg: this.beforeImg,
          bnfId: this.bnfId,
        };
        revomeBeforeAndAfter(data).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: " 삭제 되었습니다.",
              type: "success",
            });
            this.close();
          }
        });
      });
    },
    submit() {
      if (this.category == "") {
        this.$alert("카테고리를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.beforeImg == "") {
        this.$alert("이전 사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.afterImg == "") {
        this.$alert("이후 사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        bnfId: this.bnfId,
        provider: this.providerId,
        category: this.category,
        beforeImg: this.beforeImg,
        afterImg: this.afterImg,
        providerName: this.providerName,
        size: this.size,
        style: this.style,
        comment: this.comment,
      };
      if (this.editMode) {
        upadateBeforeAndAfter(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.close();
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createBeforeAndAfter(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert(" 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.close();
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
