<template>
  <div class="contents userMgt noCategory">
    <div class="title flexB">
      <h2>환불 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="username">구매자</option>
          <option value="purchaseNumber">주문번호</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one">
      <h2 class="bold">목록 (총 {{ total }}개)</h2>

      <table>
        <colgroup>
          <col style="width: 7%" />
          <col style="width: 35%" />
          <col style="width: 10%" />
          <col style="width: 8%" />
          <col style="width: 15%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th class="number">번호</th>
          <th>주문번호</th>
          <th class="center">구매자</th>
          <th class="center">결제수단</th>
          <th class="right">결제금액</th>
          <th class="center">주문상태</th>
          <th>결제일</th>
        </tr>
        <tr v-for="(data, i) in orderList" :key="i">
          <td class="number">
            {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}
          </td>

          <td>
            <router-link to @click.native="handleMove(data._id)"
              >{{ data.purchaseNumber ? data.purchaseNumber : data.merchant_uid
              }}{{ data._id }}</router-link
            >
          </td>
          <td class="center">{{ data.buyer_name }}</td>
          <td class="center">
            {{
              data.emb_pg_provider == "naverpay"
                ? "네이버페이"
                : data.emb_pg_provider == "kakaopay"
                ? "카카오페이"
                : data.pay_method == "card"
                ? "카드"
                : data.pay_method == "trans"
                ? "실시간 계좌이체"
                : ""
            }}
          </td>
          <td class="right">{{ numberFormat(data.paid_amount) }}원</td>
          <td class="center">
            <el-tag v-if="data.status == 'refund'" type="info" size="mini"
              >환불완료</el-tag
            >
            <el-tag
              v-if="data.status == 'request_refund'"
              type="danger"
              size="mini"
              >환불요청</el-tag
            >
            <el-tag
              v-if="data.status == 'reject_refund'"
              type="info"
              size="mini"
              >환불거절</el-tag
            >
          </td>
          <!-- payment, refund, cancel -->
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <div class="pagination" v-if="orderList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchOrderList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "paymentManage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      status: "",
      orderList: [],
      inputMode: "",
      searchWord: "",
      startDate: "",
      endDate: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "4-2");
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.orderList = [];
      this.total = 0;
      this.getOrderList();
    },
    search() {
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getOrderList();
    },
    handleMove(id) {
      this.$router.push({
        name: "orderDetail",
        query: { type: "refund", id: id },
      });
    },
    getOrderList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        status: "refund",
      };
      fetchOrderList(params).then((res) => {
        if (res.data.status == 200) {
          this.orderList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
