<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>결제 관리</h2>
    </div>
    <div class="box one" v-if="order">
      <!-- <h3 class="mb20">결제정보</h3> -->
      <div class="inputBox">
        <div class="flexL double">
          <p class="bold">고객명</p>
          <p class="mr10">{{ order.buyer_name }}</p>
        </div>
        <div class="flexL double">
          <p class="bold">전화번호</p>
          <p>{{ order.buyer_tel }}</p>
        </div>
        <div class="flexL double">
          <p class="bold">이메일</p>
          <p>{{ order.buyer_email }}</p>
        </div>

        <div class="flexL double">
          <p class="bold">결제수단</p>
          <p>
            {{
              order.emb_pg_provider == "naverpay"
                ? "네이버페이"
                : order.emb_pg_provider == "kakaopay"
                ? "카카오페이"
                : order.pay_method == "card"
                ? "카드"
                : order.pay_method == "trans"
                ? "실시간 계좌이체"
                : ""
            }}
            <br />
            <span v-if="order.pay_method == 'card'">
              {{ order.card_name }} ({{ order.card_number }})</span
            >
          </p>
        </div>
        <div class="flexL double">
          <p class="bold">결제금액</p>
          <p>{{ numberFormat(order.paid_amount) }}원</p>
        </div>
        <div class="flexL double">
          <p class="bold">결제일</p>
          <p>{{ moment(order.createdAt).format("YYYY.MM.DD HH:mm") }}</p>
        </div>
        <div class="flexL double">
          <p class="bold">결제상태</p>
          <p>
            <el-tag v-if="order.status == 'paid'" type="success"
              >결제완료</el-tag
            >
            <el-tag v-if="order.status == 'paid_fail'" type="warning"
              >결제실패</el-tag
            >
            <el-tag v-if="order.status == 'request_refund'" type="info"
              >환불신청</el-tag
            >
            <el-tag v-if="order.status == 'refund'" type="danger"
              >환불완료</el-tag
            >
            <el-tag v-if="order.status == 'reject_refund'" type="info"
              >환불거절</el-tag
            >
          </p>
        </div>

        <div class="flexL refundDetail" v-if="order.status == 'request_refund'">
          <p class="bold">환불 처리</p>
          <div>
            <el-button
              @click="handleRefund('refund')"
              type="success"
              plain
              size="small"
              >환불승인</el-button
            >
            <el-button
              @click="handleRefund('reject_refund')"
              type="danger"
              plain
              size="small"
              >환불거절</el-button
            >
          </div>
        </div>

        <div v-if="order.status == 'refund'" class="flexL refundDetail">
          <p class="bold">환불일</p>

          <span class="subTit">
            {{ moment(order.updatedAt).format("YYYY.MM.DD HH:mm") }}</span
          >
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to @click.native="handleBack()">목록</router-link>
        <!-- <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchOrder, createRefund } from "@/api/admin";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      order: "",
      status: "",
      type: "",
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      if (this.type == "order") {
        this.$store.dispatch("SET_NAVBAR", "4-1");
      } else {
        this.$store.dispatch("SET_NAVBAR", "4-2");
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.orderId = this.$route.query.id;
      this.getOrderDetail();
    }
  },
  methods: {
    handleBack() {
      if (this.type == "order") {
        this.$router.push("/admin/order/manage");
      } else {
        this.$router.push("/admin/order/refund/manage");
      }
    },
    getOrderDetail() {
      fetchOrder(this.orderId).then((res) => {
        if (res.data.status == 200) {
          this.order = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },

    handleRefund(type) {
      this.$confirm(
        `환불 ${
          type == "refund" ? "승인" : type == "reject_refund" ? "거절" : ""
        } 처리를 진행하시겠습니까?`,
        {
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          center: true,
        }
      )
        .then(() => {
          let data = {
            paymentId: this.orderId,
            status: type,
          };
          createRefund(data).then((res) => {
            if (res.data.status == 200) {
              this.$alert(
                `환불 ${
                  type == "refund"
                    ? "승인"
                    : type == "reject_refund"
                    ? "거절"
                    : ""
                } 처리가 완료되었습니다.`,
                {
                  confirmButtonText: "확인",
                  center: true,
                }
              )
                .then(() => {
                  this.getOrderDetail();
                })
                .catch(() => {});
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.el-button {
  padding: 9px 15px !important;
  height: unset;
}
</style>
