<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>포트폴리오 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="username">수강생 이름</option>
          <option value="title">제목</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one planner">
      <h2 class="bold">
        포트폴리오 리스트 ({{ total }}명)
        <button class="right basic">
          <router-link to="/admin/portfolio/register"
            >포트폴리오 등록</router-link
          >
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 5%" />
          <col style="width: 20%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
          <col style="width: 15%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>제목</th>
          <th>썸네일</th>
          <th>수강생명</th>
          <th>기수</th>
          <th class="center">등록일</th>
        </tr>
        <tr v-for="(data, i) in portfolioList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>
            <router-link :to="`/admin/portfolio/register?id=${data._id}`">{{
              data.title
            }}</router-link>
          </td>
          <td>
            <div class="thumbnail">
              <img :src="data.thumbnail" alt="포트폴리오 썸네일" />
            </div>
          </td>
          <td>{{ data.username }}</td>
          <td>
            {{ data.level }}
          </td>
          <td class="center">
            {{ moment(data.createdAt).format("YYYY.MM.DD") }}
          </td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="portfolioList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchPortfolioList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "portfolioManage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      status: "",
      portfolioList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "8");
  },
  mounted() {
    this.getPortfolioList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.status = "";
      this.currentPage = 1;
      this.portfolioList = [];
      this.total = 0;
      this.getPortfolioList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getPortfolioList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPortfolioList();
    },
    handleMove(id) {
      this.$router.push({ name: "portfolioRegister", query: { id: id } });
    },
    getPortfolioList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        status: this.status,
      };
      fetchPortfolioList(params).then((res) => {
        if (res.data.status == 200) {
          this.portfolioList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
