<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>후기 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option disabled selected value>선택</option>
          <option value="title">제목</option>
          <option value="comment">내용</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <!-- <div class="flex">
        <p class="bold">카테고리</p>
        <label>
          <input type="radio" value="" checked v-model="category" />전체
        </label>
        <label>
          <input type="radio" value="weddingPlace" v-model="category" />
          웨딩 장소
        </label>
      </div> -->
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one">
      <h2 class="bold">
        후기 리스트 ({{ total }}명)
        <button class="right basic">
          <router-link to="/admin/review/register">후기 등록</router-link>
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
          <col style="width: 40%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>기수</th>
          <th>제목</th>
          <th>작성자</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in reviewList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>
            {{ data.level }}
          </td>
          <td class="left">
            <router-link to @click.native="handleMove(data._id)">{{
              data.title
            }}</router-link>
          </td>
          <td>{{ data.username }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="reviewList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchReview } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      reviewList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5");
  },
  mounted() {
    this.getReviewList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.reviewList = [];
      this.total = 0;
      this.getReviewList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getReviewList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getReviewList();
    },
    handleMove(id) {
      this.$router.push({ name: "reviewregister", query: { id: id } });
    },
    getReviewList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        category: this.category,
      };
      fetchReview(params).then((res) => {
        if (res.data.status == 200) {
          this.reviewList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
