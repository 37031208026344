<template>
  <div class="contents register notice">
    <div class="title flexB">
      <h2>공지사항 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL double">
          <p class="bold">제목</p>
          <input type="text" v-model="title" />
        </div>

        <div class="flexL double">
          <p class="bold">
            내용
            <span>*</span>
          </p>
          <textarea v-model="content" />
        </div>
      </div>
      <div class="flexL">
        <p class="bold">
          이미지
          <span>*</span>
        </p>
        <div class="imgBox">
          <ul>
            <li>
              <label for="imgUpload">
                <span class="material-icons addBtn">add</span>
              </label>
              <input
                type="file"
                ref="image"
                @change="sendFile()"
                id="imgUpload"
                accept="image/*"
              />
            </li>
            <li v-for="(image, i) in images" :key="i">
              <img :src="image" alt="업로드이미지" />
              <a class="cancel" @click="handleDeleteFile(image)">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </li>
          </ul>
          <!-- <em class="notice"
              >대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em
          >-->
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/notice/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button class="point delete" v-if="editMode" @click="deleteNotice">
          삭제
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  createNotice,
  updateNotice,
  fetchNotice,
  removeNotice,
} from "@/api/admin";
import { format } from "@/mixins/format";
import { uploadFile, deleteFile } from "@/api/file";

export default {
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      noticeId: "",
      title: "",
      content: "",
      category: "",
      images: [],
      type: "",
      createdAt: "",
      updatedAt: "",
      file: null,
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "4");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.noticeId = this.$route.query.id;
      this.getNotice();
    }
  },
  methods: {
    getNotice() {
      fetchNotice(this.noticeId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.content = res.data.data.content;
          this.images = res.data.data.images;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    submit() {
      if (this.title == "") {
        this.$alert("제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.content == "") {
        this.$alert("내용을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        noticeId: this.noticeId,
        title: this.title,
        content: this.content,
        category: this.category,
        images: this.images,
      };
      if (this.editMode) {
        updateNotice(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("공지사항 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/notice/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createNotice(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("공지사항 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/notice/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
    deleteNotice() {
      this.$confirm("공지사항을 삭제하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          removeNotice(this.noticeId).then((res) => {
            if (res.data.status == 200) {
              this.$alert("공지사항이 삭제 되었습니다.", {
                confirmButtonText: "확인",
                center: true,
              }).then(() => {
                this.$router.push("/admin/notice/manage");
              });
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        })
        .catch(() => {});
    },

    sendFile() {
      // if (this.file == undefined) {
      //   return;
      // }
      this.file = this.$refs.image.files[0];
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.images.push(res.data.url);
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            this.images.splice(this.images.indexOf(url), 1);
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
  },
};
</script>
