<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>회원 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <!-- <div class="flexL">
          <p class="bold">
            아바타
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li v-if="avatar">
                <img :src="avatar" alt="사업자등록증 파일" />
                <a class="cancel" @click="handleDeleteFile(avatar, 'file')">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="avatar"
                  @change="sendFile('avatar')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
          </div>
        </div>-->

        <div class="flexL">
          <p class="bold">이름</p>
          {{ username }}
        </div>
        <div class="flexL">
          <p class="bold">이메일</p>
          {{ email }}
        </div>
        <div class="flexL">
          <p class="bold">
            타입
            <span>*</span>
          </p>
          <div class="labelBox">
            <label>
              <input type="radio" :value="true" v-model="isAdmin" />
              관리자
            </label>
            <label>
              <input type="radio" :value="false" v-model="isAdmin" />
              일반
            </label>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">생년월일</p>
          {{ birth }}
        </div>

        <div class="flexL">
          <p class="bold">전화번호</p>
          {{ mobileNo }}
        </div>
        <div class="flexL">
          <p class="bold">주소</p>
          {{ address }}
        </div>
        <div class="flexL">
          <p class="bold">가입경로</p>
          {{ joinPath }}
        </div>
        <div class="flexL">
          <p class="bold">최종학력 / 상태</p>
          {{ educationLevel }} / {{ educationStatus }}
        </div>
        <div class="flexL">
          <p class="bold">학교명 / 소재지</p>
          {{ school }} / {{ region }}
        </div>

        <div class="flexL">
          <p class="bold">이용약관 동의</p>
          {{ userTerms == true ? "동의" : "미동의" }}
        </div>
        <div class="flexL">
          <p class="bold">개인정보보호 동의</p>
          {{ privacyPolicy == true ? "동의" : "미동의" }}
        </div>
        <div class="flexL">
          <p class="bold">마케팅이용 동의</p>
          {{ marketingAgreement == true ? "동의" : "미동의" }}
        </div>

        <div class="flexL">
          <p class="bold">가입일</p>
          {{ moment(createdAt).format("YYYY년 MM월 DD일 HH:mm") }}
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/users">목록</router-link>
        <button class="basic" @click="submit">수정</button>
      </div>
    </div>

    <!-- <div class="box one">
      <div class="tableList">
        <h2 class="bold">상품 리스트</h2>
        <table v-if="products.length > 0">
          <colgroup>
            <col style="width: 8%" />
            <col style="width: 12%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 18%" />
            <col style="width: 9%" />
            <col style="width: 9%" />
            <col style="width: 14%" />
          </colgroup>
          <tr>
            <th>번호</th>
            <th>종류</th>
            <th>상품명</th>
            <th>내용</th>
            <th class="center">
              공급가
              <br />
              <el-radio v-model="includingVat" label="on" @change="handleVat"
                >VAT 포함</el-radio
              >
              <el-radio v-model="includingVat" label="off" @change="handleVat"
                >VAT 제외</el-radio
              >
            </th>
            <th class="right">가격</th>
            <th class="right">리베이트</th>
            <th class="center">수정/삭제</th>
          </tr>
          <tr v-for="(data, i) in products" :key="i">
            <td>{{ i + 1 }}</td>
            <td>상품종류</td>
            <td @click="handleCollectionModal(data, i)">
              <em>{{ data.title }}</em>
            </td>
            <td>상품내용</td>
            <td class="center">
              {{
                data.supplyPrice
                  ? numberFormat(Math.floor(data.supplyPrice * vat)) + "원"
                  : ""
              }}
            </td>
            <td class="right">
              {{ data.price ? numberFormat(data.price) + "원" : "" }}
            </td>
            <td class="right">
              {{ data.rebate ? numberFormat(data.rebate) + "원" : "" }}
            </td>
            <td class="center">
              <button class="table delete" @click="itemDelete(i, 'product')">
                삭제
              </button>
            </td>
          </tr>
        </table>
        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import {
  fetchUserDetails,
  upadateUserDetails,
  fetchUserLikesList,
} from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "UserDetail",
  data() {
    return {
      moment: moment,
      userId: "",
      isAdmin: false,
      username: "",
      email: "",
      birth: "",
      address: "",
      mobileNo: "",
      createdAt: "",
      updatedAt: "",
      dropedAt: "",
      userType: "",
      gender: "",
      school: "",
      educationLevel: "",
      educationStatus: "",
      major: "",
      joinPath: "",
      region: "",
      userTerms: false,
      privacyPolicy: false,
      marketingAgreement: false,
      isNodata: false,
      postList: [],
      total: 0,
      memoTotal: 0,
      currentPage: 1,
      userAdminType: "",
      status: "",
      categories: [],
      adminType: "",
      editMode: false,
      preference: [],
      ages: "",
      files: "",
      file: "",
      type: "",
      select: null,
      filename: "",
      likesList: "",
      avatar: "",
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  mounted() {
    this.userId = this.$route.query.id;
    this.getUserDetail();
    // this.getPlannerList();
    // this.getQnaList();
    // this.getConsultList();
    // this.getUserLikes();
  },

  methods: {
    goback() {
      this.$router.push("/admin/users");
    },
    handleTab() {
      this.postList = [];
      this.total = 0;
      this.currentPage = 1;
    },
    close() {
      this.index = null;
      this.select = null;
      this.modalVisible = "";
      this.type = "";
      this.memoVisible = false;
      this.getAgreementDetail();
    },

    //  ========================================== 회원정보 관련 함수
    getUserDetail() {
      fetchUserDetails(this.userId).then((res) => {
        if (res.data.status == 200) {
          this.isAdmin = res.data.data.isAdmin;
          this.username = res.data.data.username;
          this.email = res.data.data.email;
          this.address = res.data.data.address;
          this.birth = res.data.data.birth;
          this.mobileNo = res.data.data.mobileNo;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
          this.userTerms = res.data.data.userTerms;
          this.privacyPolicy = res.data.data.privacyPolicy;
          this.marketingAgreement = res.data.data.marketingAgreement;
          this.userType = res.data.data.userType;
          this.dropedAt = res.data.data.dropedAt;
          this.gender = res.data.data.gender;
          this.school = res.data.data.school;
          this.educationLevel = res.data.data.educationLevel;
          this.major = res.data.data.major;
          this.region = res.data.data.region;
          this.educationStatus = res.data.data.educationStatus;
          this.joinPath = res.data.data.joinPath;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    submit() {
      let data = {
        userId: this.userId,
        isAdmin: this.isAdmin,
      };

      upadateUserDetails(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "회원 정보가 수정 되었습니다.",
            type: "success",
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },

    handleCurrentChange(val) {
      this.currentPage = val;
    },
    getUserLikes() {
      let params = {
        userId: this.userId,
      };
      fetchUserLikesList(params).then((res) => {
        if (res.data.status == 200) {
          this.likesList = res.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    //  ========================================== 계약정보 관련 함수
    uncomma(str) {
      str = String(str);
      return Number(str.replace(/[^\d]+/g, ""));
    },

    sendFile() {
      if (this.files) {
        this.$alert("삭제 후 업로드 해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      if (this.file == undefined) {
        return;
      }
      this.file = this.$refs.fileUpload.files[0];
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.files = res.data.url;

            let splitUrl = this.files.split("/"); //   "/" 로 전체 url 을 나눈다
            let split2 = splitUrl[splitUrl.length - 1].split("_");
            this.filename = split2[split2.length - 1]; // 나누어진 배열의 맨 끝이 파일명이다

            this.file = "";
            document.getElementById("fileUpload").value = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("파일을 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.agreementId) {
          deletePostFile(this.agreementId, url).then((res) => {
            if (res.data.status == 200) {
              this.files = "";
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              this.files = "";
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        }
      });
    },
  },
};
</script>
