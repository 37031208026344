<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>전문가/업체 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL" v-if="editMode">
          <p class="bold">상태</p>

          <el-tag v-if="status == 'complete'" size="small">심사완료</el-tag>
          <el-button
            class="confirmBtn"
            type="primary"
            size="mini"
            @click="confirm()"
            v-if="status == 'request'"
            >심사승인</el-button
          >
        </div>
        <div class="flexL">
          <p class="bold">
            타입
            <span>*</span>
          </p>
          <div class="labelBox">
            <label>
              <input type="radio" value="예물업체" v-model="type" />
              예물업체
            </label>
            <label>
              <input type="radio" value="리세팅 전문가" v-model="type" />
              리세팅 전문가
            </label>
          </div>
        </div>
        <div class="flexL">
          <p class="bold">전문가/업체명 <span>*</span></p>
          <input type="text" v-model="providerName" />
        </div>

        <div class="flexL">
          <p class="bold">회원 정보 <span>*</span></p>
          <p v-if="editMode">
            <router-link :to="`/admin/users/detail?id=${user._id}`"
              >{{ user.username }}
              <span class="material-symbols-rounded"> demography </span>
            </router-link>
          </p>
          <div v-else class="plannerInput">
            <p @click="handleUserModal()">{{ user.username }}</p>
          </div>
        </div>

        <div class="flexL" v-if="type == '예물업체'">
          <p class="bold">대표자 이름 <span>*</span></p>
          <input type="text" v-model="ceoName" />
        </div>

        <div class="flexL">
          <p class="bold">주소</p>
          <input type="text" v-model="address" />
        </div>

        <div class="flexL">
          <p class="bold">
            검색시 노출 지역
            <span>*</span>
          </p>
          <div class="region">
            <el-select
              v-model="selectedMajor"
              placeholder="대분류 선택"
              @change="updateMinorSelect"
            >
              <el-option
                v-for="(districts, city) in regions"
                :key="city"
                :label="city"
                :value="city"
              ></el-option>
            </el-select>

            <el-select
              v-model="selectedMinor"
              placeholder="소분류 선택"
              :disabled="!selectedMajor"
              @change="handleRegion"
            >
              <el-option
                v-for="minor in minorOptions"
                :key="minor"
                :label="minor"
                :value="minor"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="flexL">
          <p class="bold">전화번호</p>
          <input type="text" v-model="phone" />
        </div>
        <div class="flexL">
          <p class="bold">개업년도</p>
          <input type="text" v-model="startedOff" />
        </div>
        <div class="flexL double services">
          <p class="bold">
            제공 서비스
            <span>*</span>
          </p>
          <el-checkbox-group v-model="services">
            <el-checkbox label="AS"></el-checkbox>
            <el-checkbox label="공방운영"></el-checkbox>
            <el-checkbox label="커스텀제작"></el-checkbox>
            <el-checkbox label="금매입"></el-checkbox>
            <el-checkbox label="특허디자인"></el-checkbox>
          </el-checkbox-group>
          <!-- <ol>
            <li>
              <span>①</span>
              <input type="text" v-model="services[0]" />
            </li>
            <li>
              <span>②</span>
              <input type="text" v-model="services[1]" />
            </li>
            <li>
              <span>③</span>
              <input type="text" v-model="services[2]" />
            </li>
            <li>
              <span>④</span>
              <input type="text" v-model="services[3]" />
            </li>
          </ol> -->
        </div>
        <div class="flexL double">
          <p class="bold">
            사업자 등록증
            <!-- <span>*</span> -->
          </p>
          <div class="fileDown">
            <input type="text" v-model="businessRegistration" />
            <a
              v-if="businessRegistration"
              class="line"
              :href="businessRegistration"
              >다운로드</a
            >
          </div>
        </div>
        <div class="flexL double">
          <p class="bold">
            소개말
            <span>*</span>
          </p>
          <textarea v-model="comments" />
        </div>

        <!-- <div v-if="editMode" class="flexL">
          <p class="bold">
            포트폴리오 정보
            <span>*</span>
          </p>
          <button class="register" @click="handlePortfolioModal('')">
            등록하기
          </button>
        </div> -->

        <div class="flexL">
          <p class="bold">
            사업장 소개 이미지
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="image"
                  @change="sendFile('image')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(image, 'images')">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
            </ul>
            <!-- <em class="notice"
              >대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em
            > -->
          </div>
        </div>
        <div class="flexL">
          <p class="bold">대표이미지/로고</p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" />
                <a
                  class="cancel"
                  @click="handleDeleteFile(thumbnail, 'thumbnail')"
                >
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="thumbnail"
                  @change="sendFile('thumbnail')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
            <!-- <em class="notice"
              >대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em
            > -->
          </div>
        </div>
        <div class="flexL">
          <p class="bold">Before & After</p>
          <button class="register" @click="handleBnfModal()">등록하기</button>
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/provider/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button class="point delete" @click="deleteProvider()">삭제</button>
      </div>
    </div>

    <div class="box one">
      <div class="tableList">
        <h2 class="bold">Befor&After 목록</h2>
        <table v-if="bnfList.length > 0">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 20%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 15%" />
            <col style="width: 25%" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th class="center">번호</th>
            <th class="center">Before & After</th>
            <th class="center">카테고리</th>
            <th class="center">다이아 크기</th>
            <th class="center">스타일</th>
            <th>설명</th>
            <th class="center">수정/삭제</th>
          </tr>
          <tr v-for="(data, i) in bnfList" :key="i">
            <td class="center">{{ i + 1 }}</td>
            <td class="center imgTd">
              <div>
                <span>BEFORE</span>
                <img :src="data.beforeImg" alt="" />
              </div>
              <div>
                <span>AFTER</span>
                <img :src="data.afterImg" alt="" />
              </div>
            </td>
            <td class="center">
              {{ data.category }}
            </td>
            <td class="center">{{ data.size }}</td>
            <td class="center">
              {{ data.style }}
            </td>
            <td class="contTd">
              <p>{{ data.comment }}</p>
            </td>
            <td class="center">
              <button @click="handleBnfModal(data._id)">수정</button>
            </td>
          </tr>
        </table>
        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible != ''">
        <BeforeAndAfter
          :providerId="providerId"
          :providerName="providerName"
          :bnfId="bnfId"
          @close="close"
          v-if="modalVisible == 'BNF'"
        />

        <UserSearch
          v-if="modalVisible == 'USER'"
          @close="close"
          @userSelect="userSelect"
        />

        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import region from "@/config/region.json";

import moment from "moment";
import { uploadFile, deleteFile } from "@/api/file";
import {
  createProvider,
  updateProvider,
  fetchProvider,
  removeProvider,
  confirmProvider,
  fetchBeforeAndAfterListById,
} from "@/api/admin";
import BeforeAndAfter from "@/components/pop/BeforeAndAfter";
import UserSearch from "@/components/pop/UserSearch";
import { format } from "@/mixins/format";
import { mapState } from "vuex";

export default {
  mixins: [format],
  components: { BeforeAndAfter, UserSearch },

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: "",
      providerId: "",
      bnfId: "",
      type: "",
      providerName: "",
      ceoName: "",
      region: "",
      services: [],
      address: "",
      mobileNo: "",
      tags: [],
      holidays: [],
      workTime: "",
      status: "",
      comments: "",
      phone: "",
      startedOff: "",
      file: "",
      images: [],
      thumbnail: "",
      currentPage: 1,
      total: 0,
      modalPage: 1,
      modalTotal: 0,
      searchResults: [],
      searchWord: "",
      user: "",
      bnfList: [],
      businessRegistration: "",

      regions: region,
      selectedMajor: "",
      selectedMinor: "",
      minorOptions: [],
    };
  },
  // watch: {
  //   fee: function (newValue) {
  //     const result = newValue
  //       .toString()
  //       .replace(/\D/g, "")
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     this.fee = result;
  //   },
  // },
  created() {
    this.$store.dispatch("SET_NAVBAR", "2");
  },
  computed: {
    ...mapState(["_id", "username"]),
  },

  mounted() {
    if (this.$route.query.id != null) {
      this.editMode = true;
      this.providerId = this.$route.query.id;
      this.getProvider();
      this.getBeforeAndAfterList();
    }
  },
  methods: {
    downloadFile() {
      const fileUrl = this.businessRegistration;
      // 파일 다운로드 처리
      const a = document.createElement("a");
      a.href = fileUrl;
      a.click();
    },
    openImage() {
      window.open(this.businessRegistration, "_blank");
    },
    updateMinorSelect() {
      this.selectedMinor = "";
      this.minorOptions = this.regions[this.selectedMajor] || [];
    },
    handleRegion() {
      this.region = this.selectedMajor + " " + this.selectedMinor;
    },
    handleUserModal() {
      this.modalVisible = "USER";
    },
    handleBnfModal(bnfId) {
      if (bnfId) {
        this.bnfId = bnfId;
      }
      this.modalVisible = "BNF";
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    close() {
      this.modalVisible = "";
      this.bnfId = null;
      this.getBeforeAndAfterList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    sendFile(type) {
      if (this.file == undefined) {
        return;
      }
      if (type == "image") {
        this.file = this.$refs.image.files[0];
      } else {
        this.file = this.$refs.thumbnail.files[0];
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "image") {
              this.images.push(res.data.url);
            } else {
              this.thumbnail = res.data.url;
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        // if (this.providerId) {
        //   deletePostFile(this.providerId, url, type).then((res) => {
        //     if (res.data.status == 200) {
        //       if (type == "file") {
        //         this.businessRegistration = "";
        //       } else if (type == "images") {
        //         this.images.splice(this.images.indexOf(url), 1);
        //       }
        //     } else {
        //       this.$alert(res.data.message, {
        //         confirmButtonText: "확인",
        //         center: true,
        //       });
        //     }
        //   });
        // } else {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = "";
            } else if (type == "images") {
              this.images.splice(this.images.indexOf(url), 1);
            }
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    // handleDelete() {
    //   this.$confirm("해당 플래너의 정보는 유지되며, 상태만 변경 됩니다.", {
    //     confirmButtonText: "확인",
    //     cancelButtonText: "취소",
    //     center: true,
    //   }).then(() => {
    //     removePlanner(this.providerId).then((res) => {
    //       if (res.data.status == 200) {
    //         this.$notify({
    //           title: "Success",
    //           message: "전문가/업체 정보가 변경 되었습니다.",
    //           type: "success",
    //         });
    //         this.$router.replace("/admin/plannermanage");
    //       }
    //     });
    //   });
    // },
    getProvider() {
      fetchProvider(this.providerId).then((res) => {
        if (res.data.status == 200) {
          this.user = res.data.data.user;
          this.type = res.data.data.type;
          this.providerName = res.data.data.providerName;
          this.region = res.data.data.region;
          this.tags = res.data.data.tags;
          this.services = res.data.data.services;
          this.comments = res.data.data.comments;
          this.images = res.data.data.images;
          this.thumbnail = res.data.data.thumbnail;
          this.status = res.data.data.status;
          this.address = res.data.data.address;
          this.phone = res.data.data.phone;
          this.startedOff = res.data.data.startedOff;
          this.ceoName = res.data.data.ceoName;
          this.businessRegistration = res.data.data.businessRegistration;

          const parts = this.region.split(" ");
          this.selectedMajor = parts[0];
          this.selectedMinor = parts[1];
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getBeforeAndAfterList() {
      fetchBeforeAndAfterListById(this.providerId).then((res) => {
        if (res.data.status == 200) {
          this.bnfList = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    submit() {
      if (this.type == "") {
        return this.$alert("타입을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.providerName == "") {
        return this.$alert("전문가/업체명을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.user == "") {
        return this.$alert("회원을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.type == "예물업체" && this.ceoName == "") {
        return this.$alert("대표자 이름을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.region == "") {
        return this.$alert("검색시 노출 지역을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.services.length == 0) {
        return this.$alert("제공 서비스를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.comments == "") {
        return this.$alert("소개말을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.images.length == 0) {
        return this.$alert("사업장 소개 이미지를 업로드해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        // } else if (this.thumbnail == "") {
        //   return this.$alert("대표이미지/로고를 업로드해주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
      }

      let data = {
        providerId: this.providerId,
        user: this.user._id,
        type: this.type,
        providerName: this.providerName,
        ceoName: this.ceoName,
        region: this.region,
        tags: this.tags,
        services: this.services,
        holidays: this.holidays,
        workTime: this.workTime,
        comments: this.comments,
        images: this.images,
        thumbnail: this.thumbnail,
        status: this.status,
        address: this.address,
        phone: this.phone,
        startedOff: this.startedOff,
        businessRegistration: this.businessRegistration,
      };
      if (this.editMode) {
        updateProvider(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("전문가/업체 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/provider/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createProvider(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("전문가/업체 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/provider/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
    async deleteProvider() {
      await this.$confirm("전문가/업체를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      });

      if (this.images.length > 0 || this.thumbnail) {
        this.$alert("이미지와 로고를 모두 삭제 후 진행해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        providerId: this.providerId,
      };
      removeProvider(data).then((res) => {
        if (res.data.status == 200) {
          this.$alert("전문가/업체 데이터가 삭제 되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          }).then(() => {
            this.$router.push("/admin/provider/manage");
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    confirm() {
      this.$confirm("심사승인을 하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          let data = {
            providerId: this.providerId,
          };
          confirmProvider(data).then((res) => {
            if (res.data.status == 200) {
              this.$alert("전문가/업체 승인처리가 되었습니다.", {
                confirmButtonText: "확인",
                center: true,
              }).then(() => {
                this.getProvider();
              });
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        })
        .catch(() => {});
    },
    userSelect(data) {
      this.user = data;
      this.close();
    },
  },
};
</script>
