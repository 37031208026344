<template>
  <div class="contents userMgt qna">
    <div class="title flexB">
      <h2>문의내역 관리</h2>
    </div>

    <div class="box one filter">
      <div class="flex">
        <h3>
          {{ title }}
        </h3>
      </div>
      <p>
        <span>{{ user.username }}</span>
        <span>{{ email }}</span>
        <span>{{ mobileNo }}</span>
        <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
      </p>
      <article>
        <pre>{{ content }}</pre>
        <div class="flex images" v-if="images.length > 0">
          <div v-for="(image, i) in images" :key="i" class="imgWrap">
            <img
              :src="image"
              alt="이미지"
              id="img"
              @click="handleImage(image)"
            />
          </div>
        </div>
      </article>
    </div>
    <div class="box one filter pb110">
      <div class="flex">
        <h3>답변하기</h3>
      </div>
      <textarea cols="45" rows="15" v-model="reply"></textarea>
      <div class="buttonWrap">
        <router-link to="/admin/qna/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ isReply ? "답변수정" : "답변작성" }}
        </button>
      </div>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <ShowImage v-if="visible" @close="close" :image="image" />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import ShowImage from "@/components/pop/ShowImage";
import { fetchQnADetail, updateQnAReply } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  name: "qnaManage",
  mixins: [format],
  components: { ShowImage },
  data() {
    return {
      visible: false,
      modalVisible: false,
      moment: moment,
      category: "",
      title: "",
      content: "",
      mobileNo: "",
      email: "",
      createdAt: "",
      isReply: false,
      user: "",
      list: [],
      image: "",
      images: [],
      reply: "",
      qnaId: "",
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "6");
  },
  mounted() {
    this.getQna();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.list = [];
      this.total = 0;
      this.getQna();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getQna();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQna();
    },
    handleImage(image) {
      this.image = image;
      this.modalVisible = true;
      this.visible = true;
    },
    close() {
      this.modalVisible = false;
      this.visible = false;
    },
    handleMove() {
      this.$router.push("/admin/qna/manage");
    },
    getQna() {
      this.qnaId = this.$route.query.id;

      fetchQnADetail(this.qnaId).then((res) => {
        if (res.data.status == 200) {
          this.content = res.data.data.content;
          this.email = res.data.data.email;
          this.mobileNo = res.data.data.mobileNo;
          this.isReply = res.data.data.isReply;
          this.reply = res.data.data.reply;
          this.createdAt = res.data.data.createdAt;
          this.title = res.data.data.title;
          this.user = res.data.data.user;
          this.images = res.data.data.images;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    submit() {
      if (this.reply == "") {
        this.$alert("답변을 작성해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      let data = {
        reply: this.reply,
        qnaId: this.qnaId,
      };
      updateQnAReply(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "문의에 답변이 전달 되었습니다.",
            type: "success",
          });
          this.$store.dispatch("getNewRequestList");
          this.$router.replace("/admin/qna/manage");
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
