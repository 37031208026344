<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>지원서 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">상태</p>

          <el-tag
            :type="
              status == 'complete'
                ? 'success' //초록
                : 'incomplete'
                ? 'info' //회색
                : 'cancel'
                ? 'danger' //빨강
                : ''
            "
            size="small"
            >{{ status == "complete" ? "작성완료" : "작성중" }}</el-tag
          >

          <!-- <el-button
            class="confirmBtn"
            type="primary"
            size="mini"
            v-if="status == 'request'"
            >심사승인</el-button
          >-->
        </div>

        <div class="flexL">
          <p class="bold">
            결과
            <span>*</span>
          </p>
          <el-select v-model="result" placeholder="합격/불합격">
            <el-option key="합격" label="합격" value="합격"></el-option>
            <el-option key="불합격" label="불합격" value="불합격"></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">이메일</p>
          <p>{{ email }}</p>
        </div>
        <div class="flexL">
          <p class="bold">전화번호</p>
          <p>{{ mobileNo }}</p>
        </div>
        <div class="flexL">
          <p class="bold">주소</p>
          <p>{{ address }}</p>
        </div>
        <div class="flexL">
          <p class="bold">생년월일</p>
          <p>{{ moment(birth).format("YYYY년 MM월 DD일") }}</p>
        </div>
        <div class="flexL">
          <p class="bold">성별</p>
          <p>{{ gender }}</p>
        </div>
        <div class="flexL">
          <p class="bold">최종학력</p>
          <p>{{ educationLevel }}</p>
        </div>
        <div class="flexL">
          <p class="bold">학교</p>
          <p>{{ school }}</p>
        </div>
        <div class="flexL">
          <p class="bold">전공</p>
          <p>{{ major }}</p>
        </div>

        <div class="flexL">
          <p class="bold">학력상태</p>
          <p>{{ educationStatus }}</p>
        </div>
        <div class="flexL">
          <p class="bold">지원경로</p>
          <p>{{ joinPath }}</p>
        </div>
        <!-- <div class="flexL">
          <p class="bold">
            접수 기간
            
          </p>
          <div class="region">
            <el-date-picker
              v-model="startDate"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>

            <el-date-picker
              v-model="endDate"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>
          </div>
        </div>-->

        <!-- <div class="flexL">
          <p class="bold">
            교육 기간
            
          </p>
          <div class="region">
            <el-date-picker
              v-model="startRegistration"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>

            <el-date-picker
              v-model="endRegistration"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>
          </div>
        </div>-->
        <!-- <div class="flexL">
          <p class="bold">전화번호</p>
          <input type="text" v-model="phone" />
        </div>-->

        <!-- <div class="flexL double services">
          <p class="bold">
            제공 서비스
            
          </p>
          <el-checkbox-group v-model="services">
            <el-checkbox label="AS"></el-checkbox>
            <el-checkbox label="공방운영"></el-checkbox>
            <el-checkbox label="커스텀제작"></el-checkbox>
            <el-checkbox label="금매입"></el-checkbox>
            <el-checkbox label="특허디자인"></el-checkbox>
          </el-checkbox-group>
        </div>-->
        <div class="flexL double">
          <p class="bold">기술역량</p>
          <div>
            <ul class="skills">
              <li v-for="(data, i) in skill" :key="i">
                <p>{{ data.tech }}</p>
                <p>{{ data.answer }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="flexL double">
          <p class="bold">지원동기</p>
          <textarea v-model="comment" />
        </div>

        <div class="flexL double">
          <p class="bold">약관동의</p>
          <div>
            <ul>
              <li class="li-flex" v-for="(data, i) in checklist_1" :key="i">
                <p>{{ data.question }}</p>
                <p>
                  {{
                    data.answer == "Yes"
                      ? "예"
                      : data.answer == "No"
                      ? "아니오"
                      : ""
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div class="flexL double">
          <p class="bold">기본자격 사항</p>
          <div>
            <ul>
              <li class="li-flex" v-for="(data, i) in checklist_2" :key="i">
                <p>{{ data.question }}</p>
                <p>
                  {{
                    data.answer == "Yes"
                      ? "예"
                      : data.answer == "No"
                      ? "아니오"
                      : ""
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div class="flexL double">
          <p class="bold">교육과정 이해 점검</p>
          <div>
            <ul>
              <li class="li-flex" v-for="(data, i) in checklist_3" :key="i">
                <p>{{ data.question }}</p>
                <p>
                  {{
                    data.answer == "Yes"
                      ? "예"
                      : data.answer == "No"
                      ? "아니오"
                      : ""
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="buttonWrap">
        <!-- <router-link :to="`/admin/application/list?id=${lectureId}`">목록</router-link> -->
        <router-link
          :to="{ path: '/admin/application/list', query: { id: lectureId } }"
          >목록</router-link
        >

        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button class="point delete" @click="deleteAppy()">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile } from "@/api/file";
import {
  updateApplication,
  fetchApplication,
  removeApplication,
} from "@/api/admin";
import { format } from "@/mixins/format";
// import { mapState } from "vuex";
export default {
  name: "applicationDetail",
  mixins: [format],
  components: {},
  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: "",
      lectureId: "",
      applyId: "",
      userId: "",
      type: "",
      title: "",
      mobileNo: "",
      email: "",
      major: "",
      username: "",
      birth: "",
      startDate: "",
      endDate: "",
      skill: [],
      services: [],
      gender: "",
      result: "",
      address: "",
      school: "",
      region: "",
      joinPath: "",
      educationLevel: "",
      educationStatus: "",
      startRegistration: "",
      endRegistration: "",
      status: "",
      comment: "",
      checklist_1: [],
      checklist_2: [],
      checklist_3: [],
      file: "",
      images: [],
      thumbnail: "",
      currentPage: 1,
      total: 0,
      modalPage: 1,
      modalTotal: 0,
      searchResults: [],
      searchWord: "",
      user: "",
      bnfList: [],
      businessRegistration: "",
    };
  },
  // watch: {
  //   fee: function (newValue) {
  //     const result = newValue
  //       .toString()
  //       .replace(/\D/g, "")
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     this.fee = result;
  //   },
  // },
  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    if (this.$route.query.id != null) {
      this.editMode = true;
      this.applyId = this.$route.query.id;
      this.getApplication();
    }
  },
  methods: {
    handleUserModal() {
      this.modalVisible = "USER";
    },
    sendFile(type) {
      if (this.file == undefined) {
        return;
      }
      if (type == "image") {
        this.file = this.$refs.image.files[0];
      } else {
        this.file = this.$refs.thumbnail.files[0];
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "image") {
              this.images.push(res.data.url);
            } else {
              this.thumbnail = res.data.url;
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = "";
            } else if (type == "images") {
              this.images.splice(this.images.indexOf(url), 1);
            }
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },

    getApplication() {
      fetchApplication(this.applyId).then((res) => {
        if (res.data.status == 200) {
          this.userId = res.data.data.user;
          this.username = res.data.data.username;
          this.email = res.data.data.email;
          this.address = res.data.data.address;
          this.mobileNo = res.data.data.mobileNo;
          this.lectureId = res.data.data.lectureId._id;
          this.school = res.data.data.school;
          this.gender = res.data.data.gender;
          this.birth = res.data.data.birth;
          this.major = res.data.data.major;
          this.region = res.data.data.region;
          this.educationLevel = res.data.data.educationLevel;
          this.educationStatus = res.data.data.educationStatus;
          this.result = res.data.data.result;
          this.userType = res.data.data.userType;
          this.startDate = res.data.data.startDate;
          this.endDate = res.data.data.endDate;
          this.startRegistration = res.data.data.startRegistration;
          this.endRegistration = res.data.data.endRegistration;
          this.comment = res.data.data.comment;
          this.status = res.data.data.status;
          this.checklist_1 = res.data.data.checklist_1;
          this.checklist_2 = res.data.data.checklist_2;
          this.checklist_3 = res.data.data.checklist_3;
          this.applyDate = res.data.data.applyDate;
          this.skill = res.data.data.skill;
          this.joinPath = res.data.data.joinPath;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    submit() {
      if (this.result == "") {
        return this.$alert("지원서 결과를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      }

      let data = {
        applyId: this.applyId,
        status: this.status,
        result: this.result,
      };
      if (this.editMode) {
        updateApplication(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert(`지원서가 ${this.result} 처리 되었습니다.`, {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push(`/admin/application/list?id=${this.lectureId}`);
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
    async deleteAppy() {
      await this.$confirm("지원서를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      });

      removeApplication(this.applyId).then((res) => {
        if (res.data.status == 200) {
          this.$alert("지원서가 삭제 되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          }).then(() => {
            this.$router.push(`/admin/application/list?id=${this.lectureId}`);
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
