<template>
  <div class="modal_body">
    <div class="modal_header">
      <p>검색</p>
      <button @click="modalReset">
        <span class="material-icons-round"> clear </span>
      </button>
    </div>
    <div class="modal_content">
      <div class="searchWrap">
        <input
          type="text"
          placeholder="이름을 입력해 주세요"
          v-model="searchWord"
          @keyup.enter="search"
        />
        <button @click="search">
          <span class="material-icons-round"> search </span>
        </button>
      </div>
      <div class="tableBox">
        <table v-if="searchResults.length > 0">
          <tr>
            <th>이름</th>
          </tr>
          <tr
            v-for="(data, i) in searchResults"
            :key="i"
            @click="userSelect(data)"
          >
            <td>{{ data.username }}</td>
          </tr>
        </table>
        <div
          class="nodata"
          v-else-if="searchResults.length == 0 && searchWord != ''"
        >
          <p>검색결과가 없습니다.</p>
        </div>
        <div class="pagination" v-if="searchResults.length > 0">
          <el-pagination
            layout="prev, pager, next"
            :total="modalTotal"
            :page-size="10"
            :page-count="6"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchUserList } from "@/api/admin";
export default {
  props: ["providerId", "providerName", "bnfId"],
  data() {
    return {
      editMode: false,
      searchWord: "",
      modalPage: 1,
      searchResults: [],
      modalTotal: 0,
      modalVisible: "",
    };
  },

  mounted() {
    this.getUserList();
  },
  methods: {
    getUserList() {
      let params = {
        page: this.modalPage,
        inputMode: "username",
        searchWord: this.searchWord,
        userType: "user",
      };
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.searchResults = res.data.users;
          this.modalTotal = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.modalPage = res.data.page;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    close() {
      this.$emit("close");
    },
    search() {
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      this.getUserList();
    },
    modalReset() {
      this.searchWord = "";
      this.userType = "";
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      this.$emit("close");
    },
    userSelect(data) {
      this.$emit("userSelect", data);
    },
    handleCurrentChange(val) {
      this.modalPage = val;
      this.getUserList();
    },
  },
};
</script>
