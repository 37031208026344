var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents register wedding funding banner"},[_vm._m(0),_c('div',{staticClass:"box one"},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"홈","name":"home"}},[_c('h2',{staticClass:"bold"},[_vm._v("홈 배너 미리보기")])]),_c('el-tab-pane',{attrs:{"label":"교육소개","name":"education"}},[_c('h2',{staticClass:"bold"},[_vm._v("교육소개 배너 미리보기")])]),_c('el-tab-pane',{attrs:{"label":"프로그램","name":"program"}},[_c('h2',{staticClass:"bold"},[_vm._v("프로그램 배너 미리보기")])])],1),(
        _vm.activeName == 'widget' &&
        _vm.images.filter((item) => item.type == 'widget').length > 0
      )?_c('div',[_c('img',{attrs:{"src":_vm.images.filter((item) => item.type == 'widget')[0].image}})]):_c('div',[(_vm.images.filter((item) => item.type == _vm.activeName).length > 0)?_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.images.filter(
            (item) => item.type == _vm.activeName
          )),function(data,i){return _c('swiper-slide',{key:i,class:{
            program: _vm.activeName == 'program',
            widget: _vm.activeName == 'widget',
          }},[(data.url)?_c('a',{attrs:{"href":data.url,"target":"_blank"}},[_c('img',{attrs:{"src":data.image}})]):_c('img',{attrs:{"src":data.image}})])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2):_c('div',{staticClass:"nodata banner"},[_c('p',[_vm._v("배너를 등록해 주세요.")])])],1)],1),_c('div',{staticClass:"box one"},[_c('h2',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.activeName == "widget" ? "위젯" : "배너")+" 업로드 ")]),_c('div',{staticClass:"flexL"},[_c('p',{staticClass:"bold"},[_vm._v("이미지")]),_c('div',{staticClass:"flexB"},[_c('div',{staticClass:"filebox"},[_c('label',{attrs:{"for":"imgUpload"}},[_vm._v("찾아보기")]),_c('input',{ref:"images",attrs:{"type":"file","id":"imgUpload","accept":"image/*","multiple":""},on:{"change":function($event){return _vm.sendFile()}}}),(_vm.activeName == 'widget')?_c('span',[_vm._v("280*222 사이즈를 권장합니다.")]):_c('span',[_vm._v("1440*"+_vm._s(_vm.activeName == "home" || _vm.activeName == "education" ? "780" : "580")+" 사이즈를 권장합니다.")])])])])]),(_vm.images.filter((item) => item.type == _vm.activeName).length > 0)?_c('div',{staticClass:"box one"},[_c('h2',{staticClass:"bold"},[_vm._v("목록")]),_c('div',{staticClass:"flexL"},[_c('div',[(_vm.images.length > 0)?_c('div',{staticClass:"flex imgBox"},_vm._l((_vm.images.filter(
              (item) => item.type == _vm.activeName
            )),function(item,i){return _c('div',{key:i,staticClass:"imgWrap banners"},[_c('img',{attrs:{"src":item.image,"alt":"이미지","id":"img"}}),_c('a',{staticClass:"cancel",on:{"click":function($event){return _vm.handleDelete(item._id, item.image)}}},[_c('img',{attrs:{"src":require("@/assets/images/icon_svg/ic_delete_wh.svg"),"alt":"icon"}})])])}),0):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title flexB"},[_c('h2',[_vm._v("배너 관리")])])
}]

export { render, staticRenderFns }