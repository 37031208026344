<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>포트폴리오 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <!-- <div class="flexL" v-if="editMode">
          <p class="bold">상태</p>

          <el-tag
            v-if="moment(startRegistration) > moment(new Date())"
            size="small"
            >접수전</el-tag
          >
          <el-tag
            v-else-if="moment(endRegistration) < moment(new Date())"
            size="small"
            >접수마감</el-tag
          >
          <el-tag v-else size="small">접수중</el-tag>
        </div> -->

        <div class="flexL">
          <p class="bold">
            제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>

        <div class="flexL">
          <p class="bold">
            교육 기수
            <span>*</span>
          </p>
          <el-select v-model="level" placeholder="부트캠프">
            <el-option
              v-for="(data, i) in lectureList"
              :key="i"
              :label="data.title"
              :value="data.title"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            수강생 이름
            <span>*</span>
          </p>
          <input type="text" v-model="username" />
        </div>
        <div class="flexL double">
          <p class="bold">
            내용상세
            <span>*</span>
          </p>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="contents"
          ></textarea>
        </div>
        <div class="flexL double">
          <p class="bold">
            포트폴리오 이미지
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="image"
                  @change="sendFile('image')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(image, 'images')">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
            </ul>
            <!-- <em class="notice"
              >대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em
            >-->
          </div>
        </div>

        <div class="flexL">
          <p class="bold">썸네일</p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" />
                <a
                  class="cancel"
                  @click="handleDeleteFile(thumbnail, 'thumbnail')"
                >
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="thumbnail"
                  @change="sendFile('thumbnail')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
            <em class="notice"
              >대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em
            >
          </div>
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/portfolio/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button class="point delete" @click="deleteLecture()">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import region from "@/config/region.json";
import { fetchLectureList } from "@/api/admin";
import moment from "moment";
import { uploadFile, deleteFile } from "@/api/file";
import {
  createPortfolio,
  updatePortfolio,
  fetchPortfolio,
  removePortfolio,
} from "@/api/admin";
import { format } from "@/mixins/format";
import { mapState } from "vuex";

export default {
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: "",
      portfolioId: "",
      contents: "",
      level: "",
      username: "",
      title: "",
      file: "",
      images: [],
      thumbnail: "",
      currentPage: 1,
      total: 0,
      searchWord: "",
      user: "",
      lectureList: [],
      businessRegistration: "",
      regions: region,
      selectedMajor: "",
      selectedMinor: "",
      minorOptions: [],
    };
  },
  // watch: {
  //   fee: function (newValue) {
  //     const result = newValue
  //       .toString()
  //       .replace(/\D/g, "")
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     this.fee = result;
  //   },
  // },
  created() {
    this.$store.dispatch("SET_NAVBAR", "8");
  },
  computed: {
    ...mapState(["_id"]),
  },

  mounted() {
    this.getLecturerList();
    if (this.$route.query.id != null) {
      this.editMode = true;
      this.portfolioId = this.$route.query.id;
      this.getPortfolio();
    }
  },
  methods: {
    getLecturerList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        status: this.status,
      };
      fetchLectureList(params).then((res) => {
        if (res.data.status == 200) {
          this.lectureList = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    sendFile(type) {
      if (this.file == undefined) {
        return;
      }
      if (type == "image") {
        this.file = this.$refs.image.files[0];
      } else {
        this.file = this.$refs.thumbnail.files[0];
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "image") {
              this.images.push(res.data.url);
            } else {
              this.thumbnail = res.data.url;
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        // if (this.portfolioId) {
        //   deletePostFile(this.portfolioId, url, type).then((res) => {
        //     if (res.data.status == 200) {
        //       if (type == "file") {
        //         this.businessRegistration = "";
        //       } else if (type == "images") {
        //         this.images.splice(this.images.indexOf(url), 1);
        //       }
        //     } else {
        //       this.$alert(res.data.message, {
        //         confirmButtonText: "확인",
        //         center: true,
        //       });
        //     }
        //   });
        // } else {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = "";
            } else if (type == "images") {
              this.images.splice(this.images.indexOf(url), 1);
            }
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },

    getPortfolio() {
      fetchPortfolio(this.portfolioId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.contents = res.data.data.contents;
          this.thumbnail = res.data.data.thumbnail;
          this.images = res.data.data.images;
          this.level = res.data.data.level;
          this.username = res.data.data.username;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    submit() {
      if (this.title == "") {
        return this.$alert(" 제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.thumbnail == "") {
        return this.$alert("썸네일 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      }

      let data = {
        portfolioId: this.portfolioId,
        title: this.title,
        contents: this.contents,
        thumbnail: this.thumbnail,
        level: this.level,
        username: this.username,
        images: this.images,
      };
      if (this.editMode) {
        updatePortfolio(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("포트폴리오가 수정 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.replace("/admin/portfolio/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createPortfolio(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("새로운 포트폴리오가 생성 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.replace("/admin/portfolio/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
    async deletePortfolio() {
      await this.$confirm(" 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      });

      if (this.images.length > 0 || this.thumbnail) {
        this.$alert(
          "포트폴리오 이미지와 썸네일을 모두 삭제 후 진행해 주세요.",
          {
            confirmButtonText: "확인",
            center: true,
          }
        );
        return;
      }

      let data = {
        portfolioId: this.portfolioId,
      };
      removePortfolio(data).then((res) => {
        if (res.data.status == 200) {
          this.$alert("데이터가 삭제 되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          }).then(() => {
            this.$router.replace("/admin/portfolio/manage");
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
